import React from "react"
import styles from '../styles/404.module.scss'
import boredCat from "../images/404-Image.png"

const ErrorPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={boredCat} alt="bored-cat"/>
      </div>
      <div className={styles.text}>
        <h1>Oops! can't seem to find this page</h1>
        <p>
          The page you are requesting for may have been deleted, moved or isn't
          available at the moment
        </p>
      </div>
    </div>
  )
}

export default ErrorPage

  